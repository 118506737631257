import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../components/Layout.jsx';
import { graphql } from 'gatsby';
export { default as Head } from '../components/Head';

// import Layout from "../components/Layout";

function Page({ data }) {
  const page = data.page;

  // If there is no slug then is not a page
  useEffect(() => {
    if (!page?.frontmatter?.slug) {
      navigate('/404');
    }
  }, []);

  return (
    <Layout>
      {page?.frontmatter?.slug?.length > 3 && (
        <div className="page-banner has-text-centered py-6">
          <h1 className="title m-2">{page.frontmatter.title}</h1>
        </div>
      )}
      <div>
        <MDXRenderer>{page.body}</MDXRenderer>
      </div>
    </Layout>
  );
}

export default Page;

export const pageQuery = graphql`
  query ($id: String) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
        excerpt
      }
      file: parent {
        ... on File {
          modifiedTime
        }
      }
      body
    }
  }
`;

// export const Head = ({
//   data: {
//     mdx: {
//       frontmatter: { title },
//     },
//   },
// }) => {
//   return <SEO title={title} />
// }
